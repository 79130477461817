import GoogleLogin from 'react-google-login';
import { FcGoogle } from 'react-icons/fc';
import { loginWithGoogle } from '@/lib/api/Auth';
import { HollowButton } from '@/components/common/Buttons';

export default function LoginWithGoogle({ signIn, setMessage }) {
  const handleGoogleLogin = async ({ tokenId }) => {
    try {
      const userData = await loginWithGoogle({ token: tokenId });
      signIn(userData);
    } catch (err) {
      if (err.message === 'First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.') {
        // The user closed the "signin with google" window
        return;
      }
      console.log(err);
      setMessage(err.message);
    }
  };

  return (
    <GoogleLogin
      clientId={process.env.GOOGLE_OAUTH_CLIENT_ID}
      render={(renderProps) => (
        <HollowButton
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <FcGoogle />
          {' '}
          <div style={{ display: 'inline-block', transform: 'translateY(1px)' }}>
            Sign in with Google
          </div>
        </HollowButton>
      )}
      onSuccess={handleGoogleLogin}
      onFailure={handleGoogleLogin}
      cookiePolicy="single_host_origin"
    />
  );
}
