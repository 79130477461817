import React from 'react';

// callback is called on submit
function useForm(callback) {
  const [validated, setValidated] = React.useState(false);
  const [formFields, setFormFields] = React.useState({});
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    callback(event);
  };

  const handleInputChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    setFormFields((prevFormFields) => ({
      ...prevFormFields, [name]: value,
    }));
  };

  return {
    handleSubmit,
    handleInputChange,
    formFields,
    validated,
    setFormFields,
  };
}

export default useForm;
