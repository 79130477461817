import styled from 'styled-components';

const HollowButton = styled.button`
  border: 1px solid #D3D3D3;
  color: gray;
  padding: 10px 10px;
  background: none;
  width: 100%;
  margin: 15px 0;
  transition: .25s;

  border-radius: ${({ theme }) => theme.borderRadius};
  &:hover {
    background: rgb(226, 226, 226);
  }
`;

export default HollowButton;
