import styled from 'styled-components';

const StyledLineWithText = styled.p`
   width: 100%; 
   text-align: center; 
   border-top: 1px solid; 
   line-height: 0px;
   margin: 25px 0; 
   border-color: #D3D3D3;

  span { 
      /* CAREFUL: This covers up the line by setting the background to white, 
      *  so it'll make a white box when used on a black background. */
      background: #fff; 
      padding: 0 10px; 
      color: #868686;
  }
`;

export default function LineWithText({ text }) {
  return <StyledLineWithText><span>{text}</span></StyledLineWithText>;
}
